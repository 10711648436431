import { useEffect } from "react";

function useRandomBackground() {
  const backgroundImage = `url('/backgrounds/${Math.ceil(
    Math.random() * 11
  )}.jpg')`;

  useEffect(() => {
    document.body.style.backgroundImage = backgroundImage;
    document.body.style.backgroundRepeat = "none";
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundAttachment = "fixed";
    document.body.style.backgroundPosition = "center";

    return () => {};
  }, [backgroundImage]);
}

export default useRandomBackground;
