import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Button from "@material-ui/core/Button";
import React from "react";

export const ErrorAndRetrySnackbar = ({ visible, onRetry, message }) => (
  <Snackbar open={visible} autoHideDuration={6000}>
    <SnackbarContent
      message={message}
      action={
        <Button variant="contained" color="secondary" onClick={onRetry}>
          RETRY
        </Button>
      }
    />
  </Snackbar>
);
