import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import { PrivacySettingsSectionSwitch } from "./PrivacySettingsSectionSwitch";
import TextField from "@material-ui/core/TextField";
import React from "react";
import { PassphraseRevealingInput } from "./PassphraseRevealingInput";

export const PrivacySettings = ({ privacyState, setPrivacyState }) => {
  const updateSelfDestructDate = (event) =>
    setPrivacyState({
      ...privacyState,
      selfDestructTime: event.target.value,
    });

  const handleCheckboxChange = (event) => {
    setPrivacyState({
      ...privacyState,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <PrivacySettingsSectionSwitch
          checked={privacyState.passphraseEnabled}
          onChange={handleCheckboxChange}
          caption="Add a self-management passphrase"
          name="passphraseEnabled"
          helperText="Add a passphrase for managing your paste later"
        />
        <FormControl fullWidth>
          {privacyState.passphraseEnabled && (
            <PassphraseRevealingInput
              value={privacyState.passphrase}
              onChange={(event) =>
                setPrivacyState({
                  ...privacyState,
                  passphrase: event.target.value,
                })
              }
            />
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <PrivacySettingsSectionSwitch
          checked={privacyState.selfDestructEnabled}
          onChange={handleCheckboxChange}
          name="selfDestructEnabled"
          caption="Set a self-destruction timer"
          helperText="Automatically delete your paste after a given period of time"
        />
        {privacyState.selfDestructEnabled && (
          <div>
            <TextField
              type="datetime-local"
              label="Self-destruct after"
              value={privacyState.selfDestructTime}
              onChange={updateSelfDestructDate}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        )}
      </Grid>
      <Grid item xs={12}>
        <PrivacySettingsSectionSwitch
          checked={privacyState.isPrivate}
          onChange={handleCheckboxChange}
          name="isPrivate"
          caption="Set private"
          helperText="Exclude this paste from public search and history"
        />
      </Grid>
    </Grid>
  );
};
