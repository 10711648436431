import React, { useEffect, useState } from "react";
import { useQuery } from "../shared/useQuery";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link as RouterLink } from "react-router-dom";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import SearchIcon from "@material-ui/icons/Search";
import Divider from "@material-ui/core/Divider";
import { findPastes } from "../shared/pasteService";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
  },
}));

export const SearchResults = (params) => {
  const classes = useStyles();
  let query = useQuery();
  let [foundPastes, setFoundPastes] = useState([]);

  const pasteService = params.pasteService;
  const needle = query.get("query");

  useEffect(() => {
    async function fetchData() {
      const result = await findPastes(needle);

      if (result.data) {
        const pastes = [...result.data].sort((m, n) => m.created < n.created);

        setFoundPastes(pastes);
      } else {
        setFoundPastes([]);
      }
    }

    fetchData();
  }, [pasteService, needle]);

  return (
    <Card className={classes.root} variant="outlined">
      <List
        subheader={<ListSubheader>Results for {needle}</ListSubheader>}
        className={classes.root}
      >
        {foundPastes &&
          foundPastes.map((it) => (
            <div>
              <Divider />
              <SearchResultListItem item={it} />
            </div>
          ))}
      </List>
    </Card>
  );
};

export const SearchResultListItem = (params) => {
  const item = params.item;
  const preview = params.item.content.substring(0, 100);

  return (
    <RouterLink to={`/pastes/${item.id}`}>
      <ListItem>
        <ListItemIcon>
          <SearchIcon />
        </ListItemIcon>
        <ListItemText primary={item.title} secondary={preview} />
      </ListItem>
    </RouterLink>
  );
};
