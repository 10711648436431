import React, { memo } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { AttachFile } from "@material-ui/icons";

export const FileAttachmentListItem = memo(({ fileName, fileSize }) => (
  <ListItem>
    <ListItemIcon>
      <AttachFile />
    </ListItemIcon>
    <ListItemText primary={fileName} />
    <ListItemSecondaryAction>
      ({Math.ceil(fileSize / 1024 / 1024)} MB)
    </ListItemSecondaryAction>
  </ListItem>
));
