import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { CodeEditor } from "../shared/codeEditor";
import { DeletePasteBackdrop } from "./DeletePasteBackdrop";
import { getPaste } from "../shared/pasteService";
import { PasteActionButtons } from "./PasteActionButtons";
import { FileAttachmentList } from "./FileAttachmentList";

const useStyles = makeStyles(() => ({
  root: {},
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

export const RenderPasteComponent = (props) => {
  const displayedPasteId = props.match.params.pasteId;
  const classes = useStyles();
  const [state, setState] = useState({ code: "" });
  const [deleteBackdropVisible, setDeleteBackdropVisible] = useState(false);

  useEffect(
    (state) => {
      async function fetchData() {
        const result = await getPaste(displayedPasteId);

        setState({
          ...state,
          id: result.data.id,
          title: result.data.title,
          created: result.data.created,
          code: result.data.content,
          fileAttachment: result.data.fileAttachment,
        });
      }

      fetchData();
    },
    [displayedPasteId]
  );

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <h2>{state.title}</h2>
        <br />
        <DeletePasteBackdrop
          pasteId={state.id}
          visible={deleteBackdropVisible === true}
          setVisible={setDeleteBackdropVisible}
        />
        <PasteActionButtons
          setDeleteBackdropVisible={setDeleteBackdropVisible}
        />
        <div id="editor-panel" role={"tabpanel"} className={classes.root}>
          <CodeEditor state={state} readonly={true} />
        </div>
        <FileAttachmentList fileAttachments={state.fileAttachment} />
      </CardContent>
    </Card>
  );
};
