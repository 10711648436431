import {Link as RouterLink} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import TimelineIcon from "@material-ui/icons/Timeline";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Badge from "@material-ui/core/Badge";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, {memo} from "react";

export const MostViewedPastesListItem = memo(({pasteId, title, numOfImpressions}) =>
    (
        <RouterLink to={`/pastes/${pasteId}`}>
            <ListItem>
                <ListItemIcon>
                    <TimelineIcon/>
                </ListItemIcon>
                <ListItemText primary={title}/>
                <ListItemSecondaryAction>
                    <Badge
                        badgeContent={numOfImpressions}
                        max={999}
                        color="primary"
                    >
                        <VisibilityIcon/>
                    </Badge>
                </ListItemSecondaryAction>
            </ListItem>
        </RouterLink>
    ));
