import React, { useEffect } from "react";
import { fetchMostViewedPastes } from "../shared/pasteService";
import { useTimer } from "../../hooks/useTimer";
import { MostViewedPastesListItems } from "./MostViewedPastesListItems";

export const MostViewedPastesList = () => {
  const [state, setState] = React.useState({ mostViewedPastes: [] });

  const fetchData = async () => {
    const result = await fetchMostViewedPastes();

    if (result.data) {
      const pastes = [...result.data]
        .filter((it) => it.pasteId !== undefined)
        .sort((m, n) => m.numOfImpresssions < n.numOfImpresssions)
        .slice(-10);

      setState({ ...state, mostViewedPastes: pastes });
    }
  };

  useTimer(fetchData, 10000);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MostViewedPastesListItems mostViewedPastes={state.mostViewedPastes} />
  );
};
