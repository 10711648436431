import React from "react";
import {RecentlyCreatedPastesListItem} from "./RecentlyCreatedPastesListItem";
import {HumanReadableDuration} from "../shared/HumanReadableTime";

export const RecentlyCreatedPastesListItems = (params) =>
    params.recentlyCreatedPastes
        ? [...params.recentlyCreatedPastes].map((item, idx) => (
            <RecentlyCreatedPastesListItem key={idx}
                                           {...item}
                                           ageStatement={HumanReadableDuration(item.created)}/>
        ))
        : null;
