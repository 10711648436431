import React from "react";
import { FileAttachmentListItem } from "./FileAttachmentListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";

export const FileAttachmentList = ({ fileAttachments }) => {
  return fileAttachments ? (
    <List subheader={<ListSubheader>File Attachments</ListSubheader>}>
      {[...fileAttachments].map((a, key) => (
        <a href={`/api/v1/files/${a.bucket}/${a.fileName}`}>
          <FileAttachmentListItem key={key} {...a} />
        </a>
      ))}
    </List>
  ) : null;
};
