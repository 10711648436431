import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { FileAttachmentListItem } from "../render-paste-component/FileAttachmentListItem";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

export const FileAttachmentComponent = ({
  existingFile,
  fileCallback,
  progress,
}) => {
  const classes = useStyles();

  const onChangeHandler = async (event) => {
    await fileCallback(event.target.files[0]);
  };

  return (
    <div>
      {existingFile ? (
        <List>
          <FileAttachmentListItem
            fileName={existingFile.name}
            fileSize={existingFile.size}
          />
        </List>
      ) : (
        <div>
          <input
            className={classes.input}
            onChange={onChangeHandler}
            id="contained-button-file"
            multiple
            type="file"
          />
          <label htmlFor="contained-button-file">
            <Button variant="contained" color="primary" component="span">
              Upload
            </Button>
          </label>
          {progress && (
            <LinearProgress variant="determinate" value={progress} />
          )}
        </div>
      )}
    </div>
  );
};
