import {Link as RouterLink} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import TimelineIcon from "@material-ui/icons/Timeline";
import ListItemText from "@material-ui/core/ListItemText";
import React, {memo} from "react";

export const RecentlyCreatedPastesListItem = memo(({pasteId, title, ageStatement}) =>
    (
        <RouterLink to={`/pastes/${pasteId}`}>
            <ListItem>
                <ListItemIcon>
                    <TimelineIcon/>
                </ListItemIcon>
                <ListItemText
                    primary={title}
                    secondary={ageStatement}
                />
            </ListItem>
        </RouterLink>
    ));
