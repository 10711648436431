export const mapStateToDto = (codeState, privacyState, fileUploadState) => ({
  id: codeState.pasteId,
  title: codeState.pasteTitle,
  content: codeState.code,
  created: new Date(),
  author: null,
  impressions: null,
  stayInCharge: {
    selfDestructAfter: privacyState.selfDestructEnabled
      ? new Date(privacyState.selfDestructTime)
      : null,
    deletePassphrase: privacyState.passphraseEnabled
      ? privacyState.passphrase
      : null,
    isPrivate: privacyState.stealthMode,
  },
  fileAttachment: fileUploadState.file
    ? [
        {
          bucket: fileUploadState.bucketId,
          fileName: fileUploadState.file.name,
          filesize: fileUploadState.file.size,
        },
      ]
    : null,
});
