import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  backdrop: {
    zIndex: 100,
    color: "#fff",
  },
});

export const CreatePasteBackdrop = (props) => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={props.open}>
      <h1>Saving paste..</h1>
      <br />
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
