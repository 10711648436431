import axios from "axios";

export const putPaste = async (pasteDto) =>
  axios.put(`/api/v1/pastes/${pasteDto.id}`, pasteDto);

export const getPaste = async (pasteId) => axios(`/api/v1/pastes/${pasteId}`);

export const deletePaste = async (pasteId, passphrase) =>
  axios.delete(`/api/v1/pastes/${pasteId}?passphrase=${passphrase}`);

export const fetchLatestPates = async () => axios("/api/v1/trends/latest");

export const findPastes = async (query) =>
  axios(`/api/v1/search?query=${query}`);

export const fetchMostViewedPastes = async () =>
  await axios("/api/v1/trends/mostViewed");

export const uploadFile = async (bucket, file, onUploadProgress) =>
  await axios.post(`/api/v1/files/${bucket}/${file.name}`, file, {
    onUploadProgress,
  });
