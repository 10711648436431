import React, { useState } from "react";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";

export const PassphraseRevealingInput = ({
  id,
  onChange,
  initialRevealState = false,
}) => {
  const [revealState, setRevealState] = useState(initialRevealState);

  return (
    <Input
      id={id}
      type={revealState ? "text" : "password"}
      onChange={onChange}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => {
              setRevealState(!revealState);
            }}
            onMouseDown={(event) => {
              event.preventDefault();
            }}
          >
            {revealState ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      }
    />
  );
};
