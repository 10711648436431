import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormHelperText from "@material-ui/core/FormHelperText";
import React from "react";

export const PrivacySettingsSectionSwitch = (params) => (
  <FormControl fullWidth>
    <FormControlLabel
      control={
        <Switch
          checked={params.checked}
          onChange={params.onChange}
          name={params.name}
          color="primary"
        />
      }
      label={params.caption}
    />
    {!params.checked && <FormHelperText>{params.helperText}</FormHelperText>}
  </FormControl>
);
