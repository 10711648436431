import Tab from "@material-ui/core/Tab";
import CodeIcon from "@material-ui/icons/Code";
import PolicyIcon from "@material-ui/icons/Policy";
import AttachmentIcon from "@material-ui/icons/Attachment";
import Tabs from "@material-ui/core/Tabs";
import React from "react";

export const CreatePasteTabBar = ({ tabValue, onSwitchTab }) => (
  <Tabs
    value={tabValue}
    onChange={onSwitchTab}
    variant="fullWidth"
    indicatorColor="primary"
    textColor="primary"
    aria-label="icon tabs example"
  >
    <Tab icon={<CodeIcon />} label={"Editor"} />
    <Tab icon={<PolicyIcon />} label={"Stay in charge"} />
    <Tab icon={<AttachmentIcon />} label={"File Attachment"} />
  </Tabs>
);
