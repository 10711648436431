import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { SearchField } from "./SearchField";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(4),
    opacity: 0.8,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));

export const Titlebar = () => {
  const classes = useStyles();
  const history = useHistory();

  const searchCallback = async (query) => {
    history.push(`/searchResults/?query=${query}`);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            <RouterLink to={"/"}>
              <b>KoalaPaste >></b> your friendly cloud clipboard
            </RouterLink>
          </Typography>
          <SearchField searchCallback={searchCallback} />
        </Toolbar>
      </AppBar>
    </div>
  );
};
