import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import React from "react";

export const PasteActionButtons = ({ setDeleteBackdropVisible }) => {
  const handleDeleteButtonClick = () => {
    setDeleteBackdropVisible(true);
  };

  return (
    <ButtonGroup
      variant="text"
      color="primary"
      aria-label="text primary button group"
    >
      <Button onClick={handleDeleteButtonClick}>Delete</Button>
    </ButtonGroup>
  );
};
