export const HumanReadableDuration = (date) => {
    const hour = 60;

    const duration = Math.floor(
        (new Date() - new Date(date)) / 60000
    );

    switch (true) {
        case (duration === 0):
            return "just a moment ago";
        case (duration < 2):
            return "one minute ago";
        case (duration < 60):
            return `${duration} minutes ago`;
        case (duration < 2 * hour):
            return `1 hour ago`;
        case (duration < 24 * hour):
            return `${Math.floor(duration / hour)} hours ago`;
        default:
            return "longer than one day ago";
    }
}
