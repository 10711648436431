import Container from "@material-ui/core/Container";
import React from "react";
import "./App.css";
import { RecentlyCreatedPastesList } from "./components/recently-created-pastes-list/RecentlyCreatedPastesList";
import { MostViewedPastesList } from "./components/most-viewed-pastes-list/MostViewedPastesList";
import { Titlebar } from "./components/titlebar/Titlebar";
import Grid from "@material-ui/core/Grid";
import { CreatePasteComponent } from "./components/create-paste-component/CreatePasteComponent";
import { RenderPasteComponent } from "./components/render-paste-component/RenderPasteComponent";
import useRandomBackground from "./hooks/randomBackground";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { SearchResults } from "./components/search-results/searchResults";

function App() {
  useRandomBackground();
  return (
    <Router>
      <div className="App">
        <Titlebar />
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Route
                path="/searchResults"
                render={(props) => <SearchResults {...props} />}
              />

              <Route
                path="/pastes/:pasteId"
                render={(props) => <RenderPasteComponent {...props} />}
              />

              <Route
                exact
                path="/"
                render={(props) => <CreatePasteComponent {...props} />}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Route
                render={() => (
                  <div>
                    <RecentlyCreatedPastesList />
                    <br />
                    <MostViewedPastesList />
                  </div>
                )}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    </Router>
  );
}

export default App;
