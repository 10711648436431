import React from "react";
import { MostViewedPastesListItem } from "./MostViewedPastesListItem";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
}));

export const MostViewedPastesListItems = ({ mostViewedPastes }) => {
  const classes = useStyles();
  return (
    <List
      subheader={<ListSubheader>Most viewed Pastes</ListSubheader>}
      className={classes.root}
    >
      {mostViewedPastes
        ? [...mostViewedPastes]
            .sort((m, n) => n.numOfImpresssions - m.numOfImpresssions)
            .map((item, idx) => (
              <MostViewedPastesListItem key={idx} {...item} />
            ))
        : null}
    </List>
  );
};
