import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";
import React from "react";

export const CodeEditor = (params) => {
  return (
    <Editor
      readOnly={params.readonly}
      onValueChange={(modifiedCode) =>
        params.setCode({ ...params.state, code: modifiedCode })
      }
      value={params.state.code}
      highlight={(code) => highlight(code, languages.js)}
      padding={10}
      style={{
        backgroundColor: "#f0f0f0",
        fontFamily: '"Fira code", "Fira Mono", monospace',
        fontSize: 14,
        minHeight: "10em",
      }}
    />
  );
};
