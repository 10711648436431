import { useEffect, useRef } from "react";

export function useTimer(elapsedTimeCallback, interval) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = elapsedTimeCallback;
  }, [elapsedTimeCallback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (interval !== null) {
      const handle = setInterval(tick, interval);
      return () => {
        clearInterval(handle);
      };
    }
  }, [elapsedTimeCallback, interval]);
}
