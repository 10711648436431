import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import {fetchLatestPates} from "../shared/pasteService";
import {useTimer} from "../../hooks/useTimer";
import {RecentlyCreatedPastesListItems} from "./RecentlyCreatedPastesListItems";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
}));

export const RecentlyCreatedPastesList = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({recentlyCreatedPastes: []});

  const fetchData = async () => {
    const result = await fetchLatestPates();

    if (result.data) {
      const pastes = [...result.data]
          .filter((it) => it.pasteId !== undefined && it.created !== undefined)
          .sort((m, n) => new Date(n.created) - new Date(m.created))
          .slice(-10);

      setState({...state, recentlyCreatedPastes: pastes});
    }
  };

  useTimer(fetchData, 10000);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <List
          subheader={<ListSubheader>Recently created Pastes</ListSubheader>}
          className={classes.root}
      >
        <RecentlyCreatedPastesListItems
            recentlyCreatedPastes={state.recentlyCreatedPastes}
        />
      </List>
  );
};



