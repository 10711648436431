import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { CodeEditor } from "../shared/codeEditor";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";
import { putPaste, uploadFile } from "../shared/pasteService";
import { mapStateToDto } from "../shared/mappers";
import { PrivacySettings } from "./PrivacySettings";
import { CreatePasteBackdrop } from "./CreatePasteBackdrop";
import { CustomTabPanel } from "./CustomTabPanel";
import { FileAttachmentComponent } from "./FileAttachmentComponent";
import { ErrorAndRetrySnackbar } from "./ErrorAndRetrySnackbar";
import { CreatePasteTabBar } from "./CreatePasteTabBar";

const useStyles = makeStyles({
  root: {},
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export const CreatePasteComponent = () => {
  const classes = useStyles();
  const history = useHistory();

  const [submitState, setSubmitState] = useState({});
  const [codeState, setCode] = useState({
    pasteTitle: "",
    pasteId: uuidv4(),
    code: "",
  });
  const [tabValue, setTabValue] = React.useState(0);
  const [privacyState, setPrivacyState] = React.useState({
    selfDestructEnabled: false,
    passphraseEnabled: false,
    isPrivate: false,
    selfDestructTime: new Date(),
    revealPassphrase: false,
  });
  const [fileUploadState, setFileUploadState] = React.useState({
    bucketId: uuidv4(),
    file: null,
  });

  const addFileCallback = async (file) => {
    setFileUploadState({ ...fileUploadState, inProgress: true });
    await uploadFile(fileUploadState.bucketId, file, async (progress) => {
      setFileUploadState({
        ...fileUploadState,
        total: progress.total,
        loaded: progress.loaded,
        progress: (progress.loaded / progress.total) * 100,
      });
    });
    setFileUploadState({ ...fileUploadState, file: file, inProgress: false });
  };

  const onSwitchTab = (event, newValue) => setTabValue(newValue);

  const handleSubmit = async () => {
    setSubmitState({ busy: true, error: null });

    const dto = mapStateToDto(codeState, privacyState, fileUploadState);

    await putPaste(dto)
      .then(function (response) {
        setSubmitState({ busy: false, error: null });
        history.push(`/pastes/${dto.id}`);
      })
      .catch(function (e) {
        setSubmitState({ busy: false, error: e });
      });
  };

  return (
    <Card className={classes.root} variant="outlined">
      <CreatePasteBackdrop open={submitState?.busy ?? false} />
      <ErrorAndRetrySnackbar
        visible={submitState.error}
        message={"Oh snap. An error occoured while submitting your paste"}
        onRetry={handleSubmit}
      />

      <CardContent>
        <form
          className="newPastesForm"
          noValidate
          autoComplete="off"
          variant="outlined"
          onSubmit={handleSubmit}
        >
          <TextField
            id="paste-title"
            label="Paste Title"
            variant="outlined"
            value={codeState.pasteTitle}
            onChange={(event) =>
              setCode({ ...codeState, pasteTitle: event.target.value })
            }
            fullWidth
          />
          <br />
          <CreatePasteTabBar tabValue={tabValue} onSwitchTab={onSwitchTab} />
          <CustomTabPanel id="editor-panel" tabValue={tabValue} tabIndex={0}>
            <CodeEditor readonly={false} state={codeState} setCode={setCode} />
          </CustomTabPanel>
          <CustomTabPanel id="privacy-panel" tabValue={tabValue} tabIndex={1}>
            <PrivacySettings
              privacyState={privacyState}
              setPrivacyState={setPrivacyState}
            />
          </CustomTabPanel>
          <CustomTabPanel
            id="fileupload-panel"
            tabValue={tabValue}
            tabIndex={2}
          >
            <FileAttachmentComponent
              existingFile={fileUploadState.file}
              progress={fileUploadState.progress}
              fileCallback={addFileCallback}
            />
          </CustomTabPanel>
        </form>
      </CardContent>
      <CardActions>
        <Button
          data-testid={"publishButton"}
          size="small"
          onClick={handleSubmit}
        >
          Publish Paste
        </Button>
      </CardActions>
    </Card>
  );
};
